import styled from "styled-components";
import SlideLeftToRight from "../Animation/SlideLeftToRight";
import SlideRightToLeft from "../Animation/SlideRightToLeft";

export const Wrapper = styled.div`
    height: 100%;
    padding: 0 1em;
    padding-bottom: 4em;
    overflow-y: scroll;
    overflow-x: hidden;
    body.mobile & {
        font-size: .8em;
    }
    &::-webkit-scrollbar-track {
        border: 1px solid #000;
        padding: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 7px;
        
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(255,255,255,0.2);
        border: 1px solid #000;
    }
`
export const HeaderContainer = styled.div`
    display: none;
    padding: 0;
    margin-bottom: 1em;
    body.mobile & {
        display: block;
    }
`
export const CloseContainer = styled(SlideRightToLeft)`
    display: flex;
    justify-content: flex-end;
    
`
export const PopupContainer = styled(SlideLeftToRight)`
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    z-index: 99999;
    padding: 2em;
    background: var(--menu-background-color);
    position: fixed;
    &.fa-IR{
        direction: rtl;
        text-align: right;
        & p, span, ul, h1, h2, h3, h4, h5, h6, strong{
            direction: rtl;
            text-align: justify;
        }
        & li{
            text-align: justify;
        }
        *[dir='ltr']{
            direction: ltr !important;
        }
    }
    &.en-US{
        direction: ltr;
        text-align: left;
        & p, span{
            direction: ltr;
            text-align: left;
        }
    }
    
    & .close-btn{
        display: none;
    }
    body.mobile & {
        width: 100%;
        background: var(--background-color);
        padding: 1em;
        & .close-btn{
            display: block;
        }
    }

`
export const OutSide = styled.div`
    top: 0;
    right: 30%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: none;
    &.open
        display: inline;
    }
    z-index: 1032;
    opacity: 0.6;
    position: fixed;
    transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    
    body.mobile &.open {
        display: none;
    }
`
export const Title = styled.h3`
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    text-transform: none;
    letter-spacing: -.02em;
    color: var(--primary-color);
    text-shadow: none;
    margin: -2px auto 6px;
    width: 100%;
    line-height: 1.3em;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    body.mobile & {
        font-size: 18px;
    }
`
