import styled from "styled-components";


export const Video = styled.video`
    width: 100%;
    height: 100%;
    body.mobile &, body.tablet & {
        &.rotate{
            width: var(--app-height);
            height: var(--app-width);
            transform: translate(-50%, -50%) scaleX(1) scaleY(1) rotate(-90deg);
            transition: transform .3s ease;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-right: -50%;
        }
        
    }
`
export const VideoContainer = styled.div`
    width: var(--app-width);
    height: var(--app-height);
    body.mobile &, body.tablet & {
        position: relative;
        overflow: visible;
        width: var(--app-width);
        height: var(--app-height);
    }
`
export const AudioContainer = styled.div`
    
`
export const Audio = styled.audio`
    visibility: hidden;
`
export const PlayerButton = styled.svg`
    width: 64px;
    transition: all ease-in-out .3s;
    cursor: pointer;
    border:2px solid var(--primary-color);
    border-radius: 100%;
    & .btn-fill, &.btn-fill{
        transition: all ease-in-out .3s;
        fill: var(--text-color);
        
    }
    &:hover{
        transition: all ease-in-out .3s;
        box-shadow: 0px 0px 10px var(--primary-color);
        & .btn-fill, &.btn-fill{
            transition: all ease-in-out .3s;
            fill: var(--primary-color);
            
        }
    }
`

export const UnmuteButton = styled.svg`
    width: 64px;
    transition: all ease-in-out .3s;
    cursor: pointer;
    border:2px solid var(--primary-color);
    border-radius: 100%;
    transition: all ease-in-out .3s;
    fill: var(--text-color);
    padding: .5em;
    &:hover{
        transition: all ease-in-out .3s;
        box-shadow: 0px 0px 10px var(--primary-color);
        fill: var(--primary-color);
    }
`

