import styled from 'styled-components';
export const Flex = styled.div`
    display: flex;

`
export const WrapperCenter = styled(Flex)`
    justify-content: center;
    align-items: center;
    
`
export const WrapperTopCenter = styled(Flex)`
    justify-content: center;
    align-items: flex-start;
    
`
export const PrimaryText = styled.span`
    color: var(--primary-color);
`
export const Title = styled.h3`
    &.en-US{
        font-family: montserrat, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        text-transform: none;
        letter-spacing: -.02em;
        text-align: left;
        line-height: 1.3em;
    }
    &.fa-IR{
        direction: rtl;
        line-height: 1.7em;
    }
    color: var(--primary-color);
    text-shadow: none;
    margin: -2px auto 6px;
    width: 100%;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    body.mobile & {
        font-size: 18px;
    }
`
