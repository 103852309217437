import styled from "styled-components";
import SlideRightToLeft from "../Animation/SlideRightToLeft";
import SlideLeftToRight from "../Animation/SlideLeftToRight";
import ResponsiveText from "../Responsive/ResponsiveText";


export const MenuContainer = styled(SlideRightToLeft)`
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    z-index: 999;
    padding: 1em;
    overflow: hidden;
    background: var(--dark-background-color);
    position: fixed;
    body.mobile & {
        width: 100%;
        height: 100%;
        background: var(--dark-background-color);
    }
`
export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
`
export const CloseContainer = styled(SlideRightToLeft)`
    width: fit-content;
`
export const LanguageContainer = styled(SlideLeftToRight)`
    width: 100%;
`
export const LanguageButton = styled.button`
    margin-right: .5em;
    border: 2px solid var(--text-color);
    background: transparent;
    color: var(--text-color);
    padding: .3em 1em;
    font-weight: bold;
    border-radius: 10px;
    transition: all ease 0.5s;
    cursor: pointer;
    &.active, &:hover{
        background: var(--primary-color);
        border-color: var(--primary-color);
        transition: all ease 0.5s;
    }
`
export const MenuItems = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    body.mobile.landscape &{
        overflow: scroll;
        align-items: flex-start;
    }
`
export const MenuWrapper = styled.div`
    width: 100%;
`
export const MenuItem = styled.button`
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: var(--text-color);
    transition: all ease-in-out .6s;
    margin: 0.5em 0;
    width: 100%;
    background: none;
    border: none;
    padding: .5em;
    text-transform: uppercase;
    &.en-US{
        letter-spacing: 6px;
        text-transform: uppercase;
    }
    &.fa-IR{
        direction: rtl;
    }
    
    &.active{
        color: var(--primary-color);
    }
    &:hover{
        color: var(--primary-color);
    }
    body.mobile & {
        font-size: 1em;
        margin: 0;
    }
`
export const OutSideMenu = styled.div`
    top: 0;
    right: 30%;
    width: calc(100% - 30%);
    .has-page{
        width: calc(100% - 70%);
    }
    height: 100%;
    cursor: pointer;
    display: none;
    background: rgba(0,0,0,.5);
    &.open{
        display: inline;
    }
    z-index: 1030;
    opacity: 0.6;
    position: fixed;
    transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    body.mobile &.open {
        display: none;
    }
`
export const MenuIconButton = styled(SlideRightToLeft)`
    width: 42px;
    height: 42px;
    padding: 6px;
    right: 0px;
    top: 4em;
    z-index: 999;
    margin-left: 16px;
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 100%;
    &.hidden{
        display: none;
    }
    &:hover {
        & div{
            span{
                width: 100%;
            }
        }
    }
    & div {
        display: inline-block;
        height: fit-content;
        width: 100%;
        & span{
            height: 2px;
            width: 100%;
            background: #fff;
            display: block;
            margin: auto;
            transition: .5s ease;
        }
        :first-child, :last-child{
            width: 70%;
            background-color: #fff;
        }
        :nth-child(2) {
            margin: 4px 0;
            background-color: #fff;
        }
    }
    body.mobile &{
        top: 2em;
        right: 1em;
    }
    @supports (-webkit-touch-callout: none){
        body.mobile &.pwa{
            top: 4em;
        }
    }
        
    
`
export const CloseIcon = styled.div`
    display: block;
    height: 2em;
    width: 2em;
    cursor: pointer;
    text-align: center;
    position: relative;
    :after, :before{
        top: 0.3em;
        height: 1.5em;
        width: 2px;
        content: ' ';
        background: #fff;
        position: absolute;
    }
    :before{
        transform: rotate(45deg);
    }
    :after{
        transform: rotate(-45deg);
    }

`
export const SocialMenuContainer = styled.ul`
    list-style: none;
    padding: 0.3em;
    margin: 0.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    body.mobile & {
        margin: 0.8em 0;
    }
`
export const Button = styled.button`
    background: none;
    border: none;
`
export const SocialMenuItem = styled.li`
    margin: 0 .4em;
    & button{
        display: flex;
        width: 2.4em;
        height: 2.4em;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    & svg{
        width: 100%;
        fill: var(--text-color);
        transition: all ease-in-out .3s;
        &:hover{
            fill: var(--primary-color);
        }
    }

`
export const SmallText = styled.div`
    font-size: .8em;
    font-weight: bold;
    color: var(--text-color);
    white-space: nowrap;
    direction: ltr;
    &.has-margin{
        margin: 0 0 1em 0;
    }
    &.fa-IR{
        direction: rtl;
    }
`
export const StoreMenuItem = styled.li`
    margin: 0 .4em;
    & div{
        display: block;
        width: 150px;
        height: 44px;
    }
    & img{
        width: 100%;
        max-width: 150px;
    }
    & small{
        
    }
    body.mobile & {
        & small{
            font-size: .7em;
        }
    }
`
export const ComingSoonContainer = styled.div`
    display: block;
    min-height: 2em;
`
export const MobileHintText = styled(ResponsiveText)`
    color: var(--primary-color);
    margin: 1em 0;
    &.fa-IR{
        direction: rtl;
    }
    &.en-US{
        direction: ltr;
    }

`