import { PlayerContainer, PlayerWrapper, UnmuteContainer } from "./Components";
import React, { createRef, useContext, useEffect, useState } from "react";
import { VideoPlayer } from "../Player/VideoPlayer";
import { AudioPlayer } from "../Player/AudioPlayer";
const BackgroundPlayer = ({url, isHls, radio, loop, poster}) => {
    const refVideoJs = createRef(null);
    return (
        <PlayerContainer>
            <PlayerWrapper>
                {radio?(
                    <AudioPlayer ref={refVideoJs} url={radio.url} videoSrc={url} poster={poster} />
                ):(
                    <VideoPlayer ref={refVideoJs} isHLS={isHls} src={url} loop={loop} isMainPlayer={true} poster={poster} />
                )} 
            </PlayerWrapper>
        </PlayerContainer>
    )
}
export default BackgroundPlayer;