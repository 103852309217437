import { Routes, Route } from "react-router-dom"
import WebsiteLayout from "../layouts/WebsiteLayout"
import PageLoader from "../scenes/Website/Home/PageLoader"
export const WebsiteRoutes = () => {
    return (
        <Routes>
            <Route element={<WebsiteLayout />}>
                <Route path="/" element={<PageLoader page="home"/>} />
                <Route path="/music" element={<PageLoader page="pmcMusicChannel"/>} />
                <Route path="/royale" element={<PageLoader page="pmcRoyaleChannel"/>} />
                <Route path="/select" element={<PageLoader page="pmcSelectRadio"/>} />
                <Route path="/planeta" element={<PageLoader page="pmcPlanetaRadio"/>} />
            </Route>
        </Routes>
    )
}