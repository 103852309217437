import { ControlsBarContainer, MenuItem, MenuItemContainer } from "./Components"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { WebsiteContext } from "../../context/WebsiteContext"
import { Pause } from "../Player/Pause"
import { Play } from "../Player/Play"
import SlideDownToUp from "../Animation/SlideDownToUp"
import { Statics } from "../General/StaticFiles"
import { AppContext } from "../../context/AppContext"
import { classNames } from "../../helper"
export const HomeNavigator = (props) => {
    const appContext = useContext(AppContext)
    const websiteContext = useContext(WebsiteContext)
    const {onCompleted, hasHomeIcon, onFullscreenClick, isFullscreen, hasPlayButton, toolbar, delayToShow} = props;
    
    return (
        <>
            <MenuItemContainer id="menu-items" className={
                classNames([
                    appContext.isLandscape?"landscape":"portrait",
                    toolbar && "toolbar",
                    props.size && props.size === "full" && 'home'
                ])
            }>
                {hasHomeIcon && 
                    <MenuItemNavigator {...props} delay={(delayToShow??0) + 0} name="home">
                        <img src={Statics.HomeIcon} alt={"home"}/>
                    </MenuItemNavigator>
                }
                <MenuItemNavigator {...props} delay={(delayToShow??0) + 500} name={"pmcMusicChannel"}>
                    <img src={Statics.MusicChannelButton} alt={"PMC Channel"}/>
                </MenuItemNavigator>
                <MenuItemNavigator {...props} delay={(delayToShow??0) + 600} name={"pmcRoyaleChannel"} hasRadio={true}>
                    <img src={Statics.RoyaleChannelButton} alt={"PMC Royale"} />
                </MenuItemNavigator>

                {hasPlayButton && (
                    <MenuItemNavigator {...props} delay={(delayToShow??0) + 600} onClick={(e) => {
                        if(websiteContext.refPlayer !== null && websiteContext.refPlayer.current !== null){
                            if(websiteContext.isPlaying){
                                websiteContext.refPlayer.current.pause();
                            }else{
                                websiteContext.refPlayer.current.play();
                            }
                        }
                        return null;
                    }}>
                        <ControlsBarContainer>
                            {websiteContext.isPlaying?(
                                <Pause onClick={(e) => websiteContext.refPlayer.current.pause()} />
                            ):(
                                <Play onClick={(e) => websiteContext.refPlayer.current.play()}/>
                            )}
                        </ControlsBarContainer>
                    </MenuItemNavigator>
                )}
                <MenuItemNavigator {...props} delay={(delayToShow??0) + 800} name={"pmcSelectRadio"} hasRadio={true}>
                    <img src={Statics.SelectChannelButton} alt={"PMC Select Radio"} />
                </MenuItemNavigator>
                <MenuItemNavigator {...props} delay={(delayToShow??0) + 1000} name={"pmcPlanetaRadio"} onNextLoad={onCompleted}>
                    <img src={Statics.PlanetaChannelButton} alt={"PMC Planeta Radio"} />
                </MenuItemNavigator>
                {onFullscreenClick && 
                    <MenuItemNavigator {...props} delay={(delayToShow??0) + 1200} onClick={onFullscreenClick}>
                        {isFullscreen?<img src={Statics.ExitFullscreenIcon} alt={"Exit fullscreen"} />:<img src={Statics.OpenFullscreenIcon} alt={"Open fullscreen"} />}
                    </MenuItemNavigator>
                }
            </MenuItemContainer>
        </>
    )
}
export const MenuItemNavigator = ({size, show, height, disableAnimation, onClick, onNextLoad, delay, children, name, path, hasRadio, onPause, onClickItem}) => {
    const websiteContext = useContext(WebsiteContext)
    const navigate = useNavigate();
    const onClickMenu = (e) => {
        if(onClick) return onClick(e);
        let timeout = 0;
        if(websiteContext.page !== name && websiteContext.refPlayer.current !== null) {
            if(onClickItem) onClickItem();
            websiteContext.refPlayer.current.pause();
            websiteContext.refPlayer.current.remove();
            timeout = 500;
        }
        if(path) return navigate(path);
        websiteContext.update({ 
            page: name??null, 
            menu: false, radio: 
            hasRadio?name:false,
            player: null
        }, timeout);
    }
    return (
        <MenuItem id={`menu-${name}`} onClick={onClickMenu} className={classNames([
            "menu-item", 
            websiteContext.page === name && "active"
        ])}>
            {disableAnimation?(
                <div>{children}</div>
            ):(
                <SlideDownToUp delay={delay}>{children}</SlideDownToUp>
            )}
            
        </MenuItem>
    )
}