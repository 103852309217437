import { useContext, useEffect, useRef, useState } from "react";
import {WebsiteContext} from "../../../context/WebsiteContext";
import Home from "./Home";
import MusicChannel from "../Channels/MusicChannel";
import RoyaleChannel from "../Channels/RoyaleChannel";
import SelectChannel from "../Channels/SelectChannel";
import PlanetaChannel from "../Channels/PlanetaChannel";
import { BottomMenuContainer, BottomMenuWrapper } from "../../../templates/desktop/WebsiteTemplate/Components";
import { HomeNavigator } from "../../../components/HomeNavigator/HomeNavigator";
import SlideDownToUp from "../../../components/Animation/SlideDownToUp";
import { AppContext } from "../../../context/AppContext";
import { classNames } from "../../../helper";
import { useSwipeable } from "react-swipeable";
import PageSwiper from "./PageSwiper";
import { UnmuteContainer, UnmuteIcon } from "../../../components/BackgroundVideo/Components";
const ROUTES = [
    "home",
    "pmcMusicChannel",
    "pmcRoyaleChannel",
    "pmcSelectRadio",
    "pmcPlanetaRadio"
];
function PageLoader({page}){
    const refPlayer = useRef(null);
    const refClickTimer = useRef(null);
    const websiteContext = useContext(WebsiteContext);
    const appContext = useContext(AppContext);
    const handlers = useSwipeable({
        onSwipedLeft: (e) => {
            if(!appContext.isLandscape) return;
            handleChangeNextPage(e)
        },
        onSwipedRight: (e) => {
            if(!appContext.isLandscape) return;
            handleChangePrevPage(e)
        },
        onSwipedUp: (e) => {
            if(appContext.isLandscape) return;
            handleChangePrevPage(e)
        },
        onSwipedDown: (e) => {
            if(appContext.isLandscape) return;
            handleChangeNextPage(e)
        },
        delta: 10,
        trackTouch: true,
        trackMouse: false
    });
    const [showMenu, setShowMenu] = useState(false);
    const [hidingMenu, setHidingMenu] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [waitPage, setWaitPage] = useState(null);
    const [activePage, setActivePage] = useState(page);
    const [hidePage, setHidePage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [lastTouch, setLastTouch] = useState(null);
    useEffect(() => {
        if(websiteContext.page !== null){
            if(waitPage){
                websiteContext.setIsShowBackVideo(true)
            }
        }
    }, [waitPage])
    useEffect(() => {
        if(websiteContext.page){
            if(websiteContext.page !== activePage){
                setShowMenu(false);
                setHidePage(activePage);
                setWaitPage(websiteContext.page);
            }else{
                websiteContext.setIsPageLoaded(false)
                setActivePage(page)
            }
        }else{
            websiteContext.setIsPageLoaded(false)
            setActivePage(page)
        }
        const preventDefault = (e) => {
            if (window.scrollY === 0 && e.touches[0].clientY > 10) {
                e.preventDefault();
            }
        };
        if(websiteContext.page && websiteContext.page !== "home")
            document.addEventListener("touchmove", preventDefault, { passive: false });
        
        return () => {
            if(websiteContext.page && websiteContext.page !== "home")
                document.removeEventListener("touchmove", preventDefault);
        };
        
    }, [websiteContext.page])
    useEffect(() => {
        if(initialized){
            if(!mouseOver){
                setHidingMenu(true);
            }
        }
    }, [initialized])
    useEffect(() => {
        if(lastTouch){
            if(refClickTimer.current) {
                clearTimeout(refClickTimer.current)
            }
            refClickTimer.current = setTimeout(() => {
                setHidingMenu(true);
            }, 5000)
        }
        
    }, [lastTouch])
    const onShowMenu = (e) => {
        if(appContext.isMobile || appContext.isTablet || hidePage || !initialized) return;
        setMouseOver(true);
        if(!initialized) return;
        setShowMenu(true);
    }
    const onHideMenu = (e) => {
        if(appContext.isMobile || appContext.isTablet || !initialized) return;
        setMouseOver(false);
        if(!initialized) return;
        setHidingMenu(true);
    }
    const onPause = () => {
        console.log(websiteContext.player)
    }
    const onPlay = () => {
        
    }
    const onTouchStart = (e) => {
        if((!appContext.isMobile && !appContext.isTablet) || hidePage || !initialized) return;
        setLastTouch(new Date())
        if(showMenu){
            if(e.target.id && e.target.id.startsWith("menu-")){
                setHidingMenu(true);
            }
        }else{
            if(e.target.id && e.target.id.startsWith("menu-")){
                setShowMenu(true);
            }
        }
        
    }
    const onClickItem = () => {
        setShowMenu(false);
    }
    const onFinishAnimation = () => {
        websiteContext.setIsPageLoaded(true);
    }
    useEffect(() => {
        setHidingMenu(false);
    }, [showMenu])
    useEffect(() => {
        if(activePage && activePage !== "home"){
            websiteContext.setIsShowBackVideo(false)
        }
        if(activePage !== "home" && !initialized){
            setShowMenu(true);
            setTimeout(() => {
                setInitialized(true);
            }, 5000)
        }
    }, [activePage])
    const onHidedPage = () => {
        setLastPage(hidePage)
        setHidePage(null);
        setActivePage(waitPage);
        setWaitPage(null);
        setLastTouch(null);
    }
    const handleChangePage = (pageName) => {
        // let timeout = 0;
        // if(websiteContext.page !== pageName && websiteContext.refPlayer.current !== null) {
        //     setShowMenu(false);
        //     websiteContext.refPlayer.current.pause();
        //     websiteContext.refPlayer.current.remove();
        //     timeout = 500;
        // }
        // websiteContext.update({ 
        //     page: pageName??null, 
        //     menu: false, 
        //     player: null
        // }, timeout);
        
        document.getElementById(`menu-${pageName}`).click()
    }
    const handleChangeNextPage = (e) => {
        let index = 1;
        if(ROUTES.indexOf(activePage) < (ROUTES.length - 1))
            index = ROUTES.indexOf(activePage) + 1;
        const nextPage = ROUTES[index];
        handleChangePage(nextPage);
    }
    const handleChangePrevPage = (e) => {
        let index = ROUTES.length - 1;
        if(ROUTES.indexOf(activePage) > 1)
            index = ROUTES.indexOf(activePage) - 1;
        const prevPage = ROUTES[index];
        handleChangePage(prevPage);
    }
    
    if(!websiteContext.isVideoLoaded) return null;
    return (
        <>
            {activePage === "home" && <PageSwiper name="home" lastPage={lastPage} waitPage={waitPage} onRest={onFinishAnimation} isHiding={hidePage === "home"} onHided={onHidedPage}><Home /></PageSwiper>}
            {activePage === "pmcMusicChannel" && <PageSwiper name="pmcMusicChannel" lastPage={lastPage} waitPage={waitPage} onRest={onFinishAnimation} isHiding={hidePage === "pmcMusicChannel"} onHided={onHidedPage} ><MusicChannel ref={refPlayer}/></PageSwiper>}
            {activePage === "pmcRoyaleChannel" && <PageSwiper name="pmcRoyaleChannel" lastPage={lastPage} waitPage={waitPage} onRest={onFinishAnimation} isHiding={hidePage === "pmcRoyaleChannel"} onHided={onHidedPage} ><RoyaleChannel ref={refPlayer}/></PageSwiper>}
            {activePage === "pmcSelectRadio" && <PageSwiper name="pmcSelectRadio" lastPage={lastPage} waitPage={waitPage}  onRest={onFinishAnimation} isHiding={hidePage === "pmcSelectRadio"} onHided={onHidedPage} ><SelectChannel ref={refPlayer}/></PageSwiper>}
            {activePage === "pmcPlanetaRadio" && <PageSwiper name="pmcPlanetaRadio" lastPage={lastPage} waitPage={waitPage} onRest={onFinishAnimation} isHiding={hidePage === "pmcPlanetaRadio"} onHided={onHidedPage} ><PlanetaChannel ref={refPlayer}/></PageSwiper>}
            {websiteContext.refPlayer.current && websiteContext.refPlayer.current.isMute() && <UnmuteContainer onClick={(e) => websiteContext.refPlayer.current.play()} className={classNames([!appContext.isLandscape && "rotate"])}><UnmuteIcon /></UnmuteContainer>}
            {activePage !== "home" && 
            <>
            <BottomMenuContainer id="menu-container" {...handlers} className={classNames([!appContext.isLandscape && "rotate"])} 
            onTouchStart={onTouchStart} onMouseMove={onShowMenu} onMouseEnter={onShowMenu} onMouseLeave={onHideMenu}>
                {showMenu && <SlideDownToUp isHiding={hidingMenu} onHided={() => setShowMenu(false)}>
                    <BottomMenuWrapper id="menu-wraper">    
                        <HomeNavigator
                            show={true} 
                            toolbar={true}
                            hasPlayButton={true}
                            disableAnimation={true}
                            hasHomeIcon={true}
                            isFullscreen={fullscreen}
                            onPlay={onPlay}
                            onPause={onPause}
                            onClickItem={onClickItem}
                            onFullscreenClick={(e) => {
                                if(!document.fullscreenElement){
                                    setFullscreen(true);
                                    if(document.documentElement.requestFullScreen) {
                                        document.documentElement.requestFullScreen();
                                    } else if(document.documentElement.mozRequestFullScreen) {
                                        document.documentElement.mozRequestFullScreen();
                                    } else if(document.documentElement.webkitRequestFullScreen) {
                                        document.documentElement.webkitRequestFullScreen();
                                    }
                                    
                                }else{
                                    setFullscreen(false);
                                    document.exitFullscreen()
                                }
                                
                            }}
                        />
                    </BottomMenuWrapper>
                </SlideDownToUp>}
            </BottomMenuContainer>
            </>
            }
                           
        </>
    )
}
export default PageLoader;