import { FullScreenVideoContainer } from "./Components";
import React, { createRef, useContext, useEffect } from "react";
import {WebsiteContext} from "../../context/WebsiteContext";
import { Statics } from "../General/StaticFiles";
import { AppContext } from "../../context/AppContext";
import { classNames } from "../../helper";

function BackgroundVideo(){
    const websiteContext = useContext(WebsiteContext)
    const appContext = useContext(AppContext)
    const refVideo = createRef();
    useEffect(() => {
        websiteContext.setIsVideoLoaded(true);
    }, [])
    useEffect(() => {
        if(refVideo.current){
            var isPlaying = refVideo.current.currentTime > 0 && !refVideo.current.paused && !refVideo.current.ended 
                && refVideo.current.readyState > refVideo.current.HAVE_CURRENT_DATA;
            if(websiteContext.isShowBackVideo){
                refVideo.current.play();
            }else{
                if(isPlaying)
                    refVideo.current.pause();
            }
        }
    }, [websiteContext.isShowBackVideo])
    return (
        <FullScreenVideoContainer className={classNames([
            (appContext.isMobile || appContext.isTablet) && !appContext.isLandscape && (websiteContext.page && websiteContext.page !== "home") && "rotate",
            websiteContext.transparency && "transparency",
            !websiteContext.isShowBackVideo && "hide"
        ])}>
            <video autoPlay muted playsInline ref={refVideo} loop={websiteContext.options.backgroundVideoLoop} 
                onCanPlayThrough={(e) => websiteContext.updateValues({ loader: false })}
            >
                <source src={Statics.DesktopIntro} type={"video/mp4"} />
            </video>
        </FullScreenVideoContainer>
    )
}
export default BackgroundVideo;