import React, { useEffect, useState } from 'react'
import ServiceWorker from '../components/General/ServiceWorker';
import { PreLoader } from '../components/PreLoader/PreLoader';
import { useTranslation } from 'react-i18next';
const Values = {
    
}
const AppState = {
    values: Values,
    update: () => {}
}
export const AppContext = React.createContext(AppState)

export const AppProvider = ({ children }) => {
    const [state, setState] = useState(AppState)
    const {i18n} = useTranslation();
    const [isLandscape, setIsLandscape] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isPwa, setIsPwa] = useState(false);
    const [language, setLanguage] = useState(null);
    const [isCacheReady, setIsCacheReady] = useState(false);
    const update = (newState, delay = 0) => {
        setTimeout(() => {
            setState({
                ...state,
                values: {
                ...state.values,
                ...newState
                }
            })
        }, delay)
    }
    const doChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("i18n", lang);
        setLanguage(lang);
    }
    useEffect(() => {
        let lang = "fa-IR"
        if(localStorage.getItem("i18n") !== null){
            lang = (localStorage.getItem("i18n"));
        }else{
            localStorage.setItem("i18n", lang);
        }
        setLanguage(lang)
        i18n.changeLanguage(lang);
        document.documentElement.lang = (lang)
      }, [])
    return (
      <AppContext.Provider value={{
            ...state,
            isCacheReady,
            isMobile,
            isPwa,
            isTablet,
            isLandscape,
            language,
            setIsCacheReady,
            setIsLandscape,
            setIsMobile,
            setIsPwa,
            setIsTablet,
            doChangeLanguage,
            update
        }}>
            <PreLoader show={!isCacheReady} />
            <ServiceWorker>
                {isCacheReady && children}
            </ServiceWorker>
      </AppContext.Provider>
    );
  };