import Hls from "hls.js"
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import {  Video, VideoContainer } from "./Components";
import { PreLoader } from "../PreLoader/PreLoader";
import { WebsiteContext } from "../../context/WebsiteContext";
import { AppContext } from "../../context/AppContext";
import { classNames } from "../../helper";
import { UnmuteContainer, UnmuteIcon } from "../BackgroundVideo/Components";

export const VideoPlayer = forwardRef(({src, isHLS, loop, muted, isMainPlayer, onLoad, poster}, ref) => {
    const videoRef = useRef(null);
    const appContext = useContext(AppContext)
    const websiteContext = useContext(WebsiteContext)
    const abortController = useRef(null);
    const [loading, setLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(null);
    const [isBuffering, setIsBuffering] = useState(false);
    const hlsRef = useRef(null);
    useImperativeHandle(ref, () => {
        return {
            play: () => {
                setLoading(true);
                handlePlay();
            },
            pause: () => {
                if(loading) return;
                handlePause();
            },
            remove: handleStop,
            isMute: () => isMuted
        }
    })
    useEffect(() => {
        if(ref.current){
            websiteContext.initVideoPlayer(ref.current);
            // if(ref.current.paused) handlePlay();
        }
    }, [ref]);
    const handleStop = () => {
        const player = videoRef.current;
        if (player) {
            player.removeEventListener('play', playing)
            player.removeEventListener('pause', handlePause)
            player.pause();
            videoRef.current = null;
        }
        
        if(abortController.current){
            abortController.current.abort();
        }
        if(isMainPlayer)
            websiteContext.clearVideoPlayer();
        if(isHLS && hlsRef.current){
            hlsRef.current.destroy();
        }
    }
    const playing = () => {
        if(!isMainPlayer) return;
        websiteContext.updateValues({
            isPlaying: true
        });
    }
    const notPlaying = () => {
        if(!isMainPlayer) return;
        setIsMuted(false)
        websiteContext.updateValues({
            isPlaying: false
        })
    }
    const handlePause = () => {
        notPlaying();
        if(isHLS && hlsRef.current){
            hlsRef.current.stopLoad();
        }
    }
    const handlePlay = () => {
        setIsMuted(false);
        if(videoRef.current && isHLS && Hls.isSupported()){
            hlsRef.current = new Hls();
            hlsRef.current.loadSource(src);
            hlsRef.current.attachMedia(videoRef.current);
            hlsRef.current.on(Hls.Events.FRAG_LOADING, () => {
                setTimeout(() => {
                    setIsBuffering(true)
                }, 0)
            })
            hlsRef.current.on(Hls.Events.FRAG_LOADED, () => {
                setTimeout(() => {
                    setIsBuffering(false)
                }, 0)
            })
            
        }
        if(videoRef.current && !isHLS){
            videoRef.current.src = src;  
        }
        videoRef.current.muted = muted;
        videoRef.current.volume = 1;
        videoRef.current.play().then(() => {
            abortController.current = new AbortController();
            setIsMuted(false)
            websiteContext.updateValues({
                isPlaying: true
            });
            setLoading(false);
            videoRef.current.addEventListener('play', playing)
            videoRef.current.addEventListener('pause', handlePause)
        }).catch((err) => {
            if(err.name === "NotAllowedError"){
                setIsMuted(true)
                setLoading(false);
            }
            
        });
        
        

    }
    useEffect(() => {
        handlePlay();
        return () => {
            handleStop();
        }
    }, [])
    const onCanPlayThrough = (e) => {
        setTimeout(() => {
            setLoading(false);
            if(onLoad) onLoad();
        }, 0)
    }
    return (
        <VideoContainer ref={ref} onClick={(e) => console.log("Clicked")}>
            {(loading) && <PreLoader show={true} disableBackground={true}/>}
            <Video ref={videoRef} loop={!isHLS?loop:false} 
             poster={poster} 
             muted={muted}
             controls={false} 
             autoPlay={false}
             playsInline={true}
             webkit-playsinline
             className={classNames([
                !appContext.isLandscape && "rotate"
             ])}
             onCanPlayThrough={onCanPlayThrough}>
                <p>This browser doesn't support hls</p>
            </Video>
            
        </VideoContainer>
        
    )
})