import { Container, Footer, Header, HomeContainer, HomeMenu, HomeMenuContainer, HomeMenuWrapper, MainContent, WebsiteLogo, 
    WebsiteTitle, WebsiteTitleContainer } from "./Components";
import { useContext, useEffect, useState } from "react";
import {WebsiteContext} from "../../../context/WebsiteContext";
import {Container as BaseContainer} from "react-bootstrap"
import { HomeNavigator } from "../../../components/HomeNavigator/HomeNavigator";
import { MenuIcon } from "../../../components/WebsiteMenu/WebsiteMenu";
import SlideUpToDown from "../../../components/Animation/SlideUpToDown";
import SlideDownToUp from "../../../components/Animation/SlideDownToUp";
import ResponsiveText from "../../../components/Responsive/ResponsiveText";
import { Statics } from "../../../components/General/StaticFiles";
import { classNames } from "../../../helper";
import Page from "../../../components/General/Page";
import InstallPopup from "../../../components/InstallPopup/InstallPopup";
import { AppContext } from "../../../context/AppContext";
function Home({style, className}){
    const websiteContext = useContext(WebsiteContext);
    const appContext = useContext(AppContext);
    const getYear = () => {
        return (
            <SlideDownToUp delay={2500} onNextLoad={'menuIcon'}>
                <ResponsiveText text={`PMC NETWORK 2003 © ${new Date().getFullYear()} DUBAI, UNITED ARAB EMIRATES. ALL RIGHTS RESERVED.`} min={9} max={14} />
            </SlideDownToUp>
        )
        
    }
    if(!websiteContext.isPageLoaded) return null;
    return (
        <Page path={"/"} title="PMC" description={"Persian and international music videos"}>
            <Container className={className} style={style}>
                <BaseContainer>
                    <InstallPopup />
                    <Header className={websiteContext.transparency && "transparency"}>
                        <MenuIcon delay={2700} hidden={websiteContext.menu}/>
                    </Header>
                    <MainContent>
                        <HomeContainer>
                            <WebsiteTitleContainer className={classNames(["header", websiteContext.transparency && "transparency", appContext.isPwa && "pwa"])}>
                                <SlideUpToDown delay={200}>
                                    <WebsiteLogo>
                                        <img src={Statics.Logo} alt={"pmc-logo"}/>
                                    </WebsiteLogo>
                                </SlideUpToDown>
                                <SlideUpToDown delay={700}>
                                    <WebsiteTitle>YOUR FEEL-GOOD NETWORK</WebsiteTitle>
                                </SlideUpToDown>
                            </WebsiteTitleContainer>
                        
                            <HomeMenuContainer className={classNames(["menu", websiteContext.transparency && "transparency"])}>
                                <HomeMenuWrapper>
                                    <HomeMenu>
                                        <HomeNavigator size="full" show={true} delayToShow={1000}/>
                                    </HomeMenu>
                                    <Footer>
                                        {getYear()}
                                    </Footer>
                                </HomeMenuWrapper>
                            </HomeMenuContainer>
                        </HomeContainer>
                    </MainContent>
                </BaseContainer>
                
            </Container>
        </Page>
    )
}
export default Home;