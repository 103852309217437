import { useContext, useEffect, useRef, useState } from "react";
import { Container, InstallButton, InstallButtonContainer, InstallText, Wrapper } from "./Components";
import { WebsiteContext } from "../../context/WebsiteContext";
import { classNames } from "../../helper";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";

const InstallPopup = () => {
    const websiteContext = useContext(WebsiteContext);
    const appContext = useContext(AppContext);
    const [show, setShow] = useState(true)
    const [isHiding, setIsHiding] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const { t } = useTranslation();
    const onHided = () => {
        setShow(false);
        setIsHiding(false);
    }
    useEffect(() => {
        
        const isIOS = /iphone|ipod|ipad/i.test(navigator.userAgent);
        setIsIOS(isIOS);
        const handleInstallEvent = (event) => {
            if(localStorage.getItem('pwa') !== null) return;
            event.preventDefault();
            setDeferredPrompt(event)
        }
        window.addEventListener('beforeinstallprompt', handleInstallEvent)
        const timeout = setTimeout(() => {
            setIsHiding(true);
            clearTimeout(timeout);
        }, 5000);
        return () => {
            window.removeEventListener('beforeinstallprompt', handleInstallEvent)
            clearTimeout(timeout)
        }
    }, [])
    const onClickInstall = (e) => {
        if(!deferredPrompt) return;
        setIsHiding(true);
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiseResult) => {
            setDeferredPrompt(null)
            if(choiseResult.outcome === "accepted"){
                localStorage.setItem('pwa', 'accepted')
                console.log("User accepted install prompt");
            }else{
                localStorage.setItem('pwa', 'dismissed')
                console.log("User dismissed the install prompt")
            }
        })
    }
    return null;
    if((websiteContext.page !== null && websiteContext.page !== "home") || appContext.isPwa) return null;
    return (
        <Container delay={3000} isHiding={isHiding} onHided={onHided} className={classNames([!show && "hide", appContext.language])}>
            <Wrapper>
                <InstallText dangerouslySetInnerHTML={{ __html: isIOS?t('installIOSHintText'):(deferredPrompt?t('installAndroidHintText'):t('installAndroidOnlyHintText')) }} />
                {deferredPrompt && <InstallButtonContainer>
                    <InstallButton onClick={onClickInstall}>{t('install')}</InstallButton>
                </InstallButtonContainer>}
            </Wrapper>
            
        </Container>
    )
}
export default InstallPopup;