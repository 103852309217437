import BackgroundPlayer from "../../../components/BackgroundVideo/BackgroundPlayer";
import ChannelsTemplate from "../../../templates/desktop/WebsiteTemplate/ChannelsTemplate";
import { Statics } from "../../../components/General/StaticFiles";
import Page from "../../../components/General/Page";

const SelectChannel = ({style}) => {
    return (
        <Page path={"/select"} title="PMC SELECT" description={"Persian songs and DJ remixes"}>
            <ChannelsTemplate style={style}>
                <BackgroundPlayer 
                    url={Statics.SelectMovie} 
                    radio={{
                        url: "https://s5.radio.co/sf0a1af54c/listen",
                        type: 'audio/mpeg'
                    }}
                    poster={Statics.SelectMoviePreLoad}
                    loop={true}
                />
            </ChannelsTemplate>
        </Page>
        
    )
}
export default SelectChannel;