import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

const ResponsiveContainer = ({children}) => {
    const appContext = useContext(AppContext);
    useEffect(() => {
        if(typeof window !== undefined && window?.navigator){
            const isPwa = (
                window.matchMedia('(display-mode: fullscreen)').matches ||
                window.matchMedia('(display-mode: standalone)').matches ||
                //@ts-ignore
                (window.navigator?.fullscreen === true) || 
                (window.navigator?.standalone === true)
            );
            appContext.setIsPwa(isPwa);
        }
        const detectOrientation = () => {
            document.body.classList.remove("tablet");
            document.body.classList.remove("mobile");
            document.body.classList.remove("landscape");
            document.body.classList.remove("portrait");
            const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent) || window.innerWidth <= 768;
            const isTablet = /iPad|Android(?!.*Mobile)|Tablet|PlayBook|Silk/i.test(navigator.userAgent) || (window.innerWidth > 768 && window.innerWidth <= 1200);
            const isLandscape = window.matchMedia("(orientation: landscape)").matches;
            const isPortrait = window.matchMedia("(orientation: portrait)").matches;
            if(isMobile)
                document.body.classList.add("mobile");
            if(isTablet)
                document.body.classList.add("tablet");

            document.body.classList.add(isLandscape?"landscape":"portrait");
            appContext.setIsLandscape(isLandscape)
        }
        const handleChangeWindowSize = () => {
            document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
            document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`);
            appContext.setIsMobile(window.innerWidth <= 768);
            appContext.setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1200)
            detectOrientation();
        }
        
        handleChangeWindowSize();
        window.addEventListener('resize', handleChangeWindowSize);
        window.addEventListener('orientationchange', handleChangeWindowSize);
        return () => {
            window.removeEventListener('resize', handleChangeWindowSize);
            window.removeEventListener('orientationchange', handleChangeWindowSize);
        }

    }, [])
    return children;
}
export default ResponsiveContainer;