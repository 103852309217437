import styled from "styled-components";


export const PreLoaderContainer = styled.div`
    position: fixed;
    table-layout: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    top: 0;
    display:none;
    background: #000;
    &.show{
        display: table;
    }
    &.no-bg{
        background: transparent;
    }
`
export const PreLoaderStatus = styled.div`
    display: table-cell;
    vertical-align: middle;
`
export const PreLoaderPosition = styled.div`
    position: relative;
    margin: 0 auto;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
`
export const Loader = styled.div`
    position: relative;
    left: 50%;
    top: 50%;
    margin-top: 2px;
    width: 45px;
    height: 45px;
    margin-left: -22px;
    border: 3px solid rgba(255, 255, 255, .15);
    &.small{
        width: 24px;
        height: 24px;
        margin-left: -12px;
        border: 3px solid rgba(255, 255, 255, 0);
        & span{
            width: 24px;
            height: 24px;
            border-top: 3px solid rgba(255, 255, 255, .3);
        }
    }
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -ms-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
    
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    & span{
        position: absolute;
        border: 3px solid transparent;
        width: 45px;
        height: 45px;
        border-top: 3px solid rgba(255, 255, 255, .75);
        top: -3px;
        left: -3px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
    }
`
