import BackgroundPlayer from "../../../components/BackgroundVideo/BackgroundPlayer";
import ChannelsTemplate from "../../../templates/desktop/WebsiteTemplate/ChannelsTemplate";
import Page from "../../../components/General/Page";
const RoyaleChannel = ({style}) => {
    return (
        <Page path={"/royale"} title="PMC ROYALE" description={"House music by top global DJs"}>
            <ChannelsTemplate style={style}>
                <BackgroundPlayer url={"https://rohls.pmc.live/hls/stream.m3u8"} isHls={true}/>
            </ChannelsTemplate>
        </Page>
        
    )
}
export default RoyaleChannel;