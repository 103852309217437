import { useContext } from "react";
import { useSpring, animated } from "react-spring";
import { WebsiteContext } from "../../context/WebsiteContext";


const SlideRightToLeft = ({id, onClick, children, delay, duration, hidingDuration, className, onNextLoad, onRest, isHiding, onHided, hideMode}) => {
    const websiteContext = useContext(WebsiteContext);
    const animation = useSpring({
        from: {
            opacity: isHiding?"1":"0",
            ...(!isHiding && {
                transform: `translate(100%, 0%)`
            }),
            ...(isHiding && {
                transform: `translate(0%, 0%)`
            })
        },
        to: async (next) => {
            await next({
                opacity: isHiding?"0":"1",
                ...(!isHiding && {
                    transform: `translate(0%, 0%)`
                }),
                ...(isHiding && (!hideMode) && {
                    transform: `translate(100%, 0%)`
                }),
                ...(isHiding && (hideMode && hideMode === "top") && {
                    transform: `translate(0%, -100%)`
                }),
                ...(isHiding && (hideMode && hideMode === "left") && {
                    transform: `translate(-100%, 0%)`
                }),
                ...(isHiding && (hideMode && hideMode === "down") && {
                    transform: `translate(0%, 100%)`
                })
            })
        },
        delay: delay,
        
        config: {
            duration: (isHiding && hidingDuration)?hidingDuration:(duration?duration:400),
            tension: 200, 
            friction: 20
        },
        onRest: () => {
            if(isHiding && onHided){
                onHided();
            }else{
                if(onNextLoad)
                    websiteContext.animate(onNextLoad);
                
                if(onRest) onRest();
            }
        }
    })
    return (
        <animated.div onClick={onClick} className={className} style={animation} id={id}>{children}</animated.div>
    )
}
export default SlideRightToLeft;