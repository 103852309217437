import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
const Page = ({ path, title, description, children }) => {
    const [startTime, setStartTime] = useState(Date.now());
    useEffect(() => {
        setStartTime(Date.now())
        ReactGA.send({
            hitType: "pageview",
            page: path,
            title: title
        });
        return () => {
            const endTime = Date.now();
            const duration = (endTime - startTime);
            ReactGA.event("custom_timing", {
                name: "time_on_page",
                value: duration,
                category: "Engagement",
                page_path: path
            })
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>{title}</title>        
                <meta name="description" content={description} />
            </Helmet>
            {children}
        </>
    )
}
export default Page;