import { classNames } from "../../helper";
import { Loader, PreLoaderContainer, PreLoaderPosition, PreLoaderStatus } from "./Components";

export const PreLoader = ({show, disableBackground, small}) => {
    return (
        <PreLoaderContainer className={classNames([
            show && 'show',
            disableBackground && 'no-bg'   
        ])}>
            <PreLoaderStatus>
                <PreLoaderPosition>
                    <Loader className={classNames([small && "small"])}>
                        <span></span>
                    </Loader>
                </PreLoaderPosition>
            </PreLoaderStatus>
        </PreLoaderContainer>
    );
}