import { useContext } from "react";
import { PageContainerSlideUpToDown, PageContainerSlideDownToUp, PageContainerSlideLeftToRight, PageContainerSlideRightToLeft } from "./Components";
import { AppContext } from "../../../context/AppContext";

const ROUTES = [
    "home",
    "pmcMusicChannel",
    "pmcRoyaleChannel",
    "pmcSelectRadio",
    "pmcPlanetaRadio"
];
const SHOWING_DURATION = 500;
const HIDING_DURATION = 300;
const PageSwiper = (props) => {
    const {name, lastPage, waitPage, isHiding, children} = props;
    const appContext = useContext(AppContext);
    
    if(appContext.isMobile || appContext.isTablet){
        if(!appContext.isLandscape){
            let hid = "down";
            if(name && waitPage && isHiding){
                if(ROUTES.indexOf(waitPage) < ROUTES.indexOf(name)){
                    if((ROUTES.indexOf(waitPage) === 1 && ROUTES.indexOf(name) === 4)){
                        hid = "down"
                    }else{
                        hid = null;
                    }   
                }else{
                    if((ROUTES.indexOf(waitPage) === 4 && ROUTES.indexOf(name) === 1)){
                        hid = null
                    }
                }
                
            }
            if(name && !isHiding && lastPage){
                if(ROUTES.indexOf(lastPage) > ROUTES.indexOf(name)){
                    if(ROUTES.indexOf(lastPage) === 4 && ROUTES.indexOf(name) === 1){
                        return (<PageContainerSlideUpToDown hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideUpToDown>)
                    }else{
                        return (<PageContainerSlideDownToUp hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideDownToUp>)
                    }
                        
                }else{
                    if(ROUTES.indexOf(name) === 4 && ROUTES.indexOf(lastPage) === 1){
                        return (<PageContainerSlideDownToUp hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideDownToUp>)
                    }
                }
            }
            return (<PageContainerSlideUpToDown hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideUpToDown>)
        }
        let hid = "left"
        if(name && waitPage && isHiding){
            if(ROUTES.indexOf(waitPage) < ROUTES.indexOf(name)){
                if((ROUTES.indexOf(waitPage) === 1 && ROUTES.indexOf(name) === 4)){
                    hid = "left"
                }else{
                    hid = null;
                }
            }else{
                if((ROUTES.indexOf(waitPage) === 4 && ROUTES.indexOf(name) === 1)){
                    hid = null
                }
            }   
        }
        if(name && !isHiding && lastPage){
            if(ROUTES.indexOf(lastPage) > ROUTES.indexOf(name)){
                if(ROUTES.indexOf(lastPage) === 4 && ROUTES.indexOf(name) === 1){
                    return (<PageContainerSlideRightToLeft hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideRightToLeft>)
                }else{
                    return (<PageContainerSlideLeftToRight hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideLeftToRight>)
                }  
            }else{
                if(ROUTES.indexOf(name) === 4 && ROUTES.indexOf(lastPage) === 1){
                    return (<PageContainerSlideLeftToRight hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideLeftToRight>)
                }    
            }
        }
        return (<PageContainerSlideRightToLeft hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={hid}>{children}</PageContainerSlideRightToLeft>)
    }
    return (<PageContainerSlideDownToUp hidingDuration={HIDING_DURATION} duration={SHOWING_DURATION} {...props} id="page-container" hideMode={"up"}>{children}</PageContainerSlideDownToUp>)
    
}
export default PageSwiper;