import { useContext, useState } from "react"
import { WebsiteContext } from "../../context/WebsiteContext"
import { CloseIcon, LanguageButton, Button, LanguageContainer, HeaderContainer, ComingSoonContainer, MenuContainer, MenuIconButton, MenuItem, MenuItems, 
    MenuWrapper, OutSideMenu, SmallText, SocialMenuContainer, SocialMenuItem, StoreMenuItem, CloseContainer, 
    MobileHintText} from "./Components"
import SlideRightToLeft from "../Animation/SlideRightToLeft";
import SlideDownToUp from "../Animation/SlideDownToUp";
import SlideUpToDown from "../Animation/SlideUpToDown";
import PagePopup from "../PagePopup/PagePopup";
import { useTranslation } from "react-i18next";
import { classNames, openInNewTab } from "../../helper";
import { Statics } from "../General/StaticFiles";
import { AppContext } from "../../context/AppContext";
import { useSwipeable } from "react-swipeable/es";
export const WebsiteMenu = () => {
    const websiteContext = useContext(WebsiteContext);
    const appContext = useContext(AppContext);
    const [isClosingMenu, setIsClosingMenu] = useState(false);
    const [page, setPage] = useState(null);
    const [comingSoon, setComingSoon] = useState(false);
    const [hiding, setHiding] = useState(false);
    
    const {t} = useTranslation();
    const onCloseMenuButton = (e) => {
        if(page !== null)
            setPage(null)
        else
            setIsClosingMenu(true);
    }
    const onCloseMenu = () => {
        setIsClosingMenu(false);
        setHiding(false);
        setComingSoon(false)
        setPage(null)
        websiteContext.update({
            menu: false
        })
        
    }
    const onOpenPage = (name) => {
        setPage(name)
    }
    const onCloseMenuHandler = () => {
        setHiding(false);
        setComingSoon(false)
        setIsClosingMenu(true);    
    }
    const onClosePage = () => {
        setIsClosingMenu(false);
        setHiding(false);
        setComingSoon(false)
        setPage(null)
    }
    if(!websiteContext.menu) return null;
    return (
        <>
            <PagePopup name={page} onClose={onClosePage} onCloseMenu={onCloseMenuHandler}/>
            <MenuContainer isHiding={isClosingMenu} onHided={onCloseMenu}>
                <HeaderContainer>
                    <LanguageContainer delay={3000}>
                        <LanguageButton className={(appContext.language === "en-US") && "active"} onClick={(e) => appContext.doChangeLanguage("en-US")}>English</LanguageButton>
                        <LanguageButton className={appContext.language === "fa-IR" && "active"} onClick={(e) => appContext.doChangeLanguage("fa-IR")}>فارسی</LanguageButton>
                    </LanguageContainer>
                    <CloseContainer delay={3000}>
                        {!appContext.isMobile && <CloseIcon onClick={onCloseMenuButton}><i></i><i></i></CloseIcon>}
                        {(appContext.isMobile && page === null) && <CloseIcon onClick={onCloseMenuHandler}><i></i><i></i></CloseIcon>}
                    </CloseContainer>
                </HeaderContainer>
    
                <MenuItems>
                    <MenuWrapper>
                        <SlideRightToLeft delay={300}>
                            <MenuItem className={classNames([appContext.language, page === "submitVideo" && "active" ])}  onClick={(e) => onOpenPage("submitVideo")}>
                                {t("submitYourVideo")}
                            </MenuItem>
                        </SlideRightToLeft>
                        <SlideRightToLeft delay={500}>
                            <MenuItem className={classNames([appContext.language, page === "advertising" && "active"])} onClick={(e) => onOpenPage("advertising")}>
                                {t("advertising")}
                            </MenuItem>
                        </SlideRightToLeft>
                        <SlideRightToLeft delay={700}>
                            <MenuItem className={classNames([appContext.language, page === "satelliteFrequencies" && "active"])} onClick={(e) => onOpenPage("satelliteFrequencies")}>
                                {t("satelliteFrequencies")}
                            </MenuItem>
                        </SlideRightToLeft>
                        {/* <SlideRightToLeft delay={900}>
                            <MenuItem className={classNames([appContext.language, websiteContext.modal === "PMCSms" && "active"])} onClick={(e) => onOpenPage("PMCSms")}>
                                {t("pmcSmsTitle")}
                            </MenuItem>
                        </SlideRightToLeft> */}
                        <SlideRightToLeft delay={900}>
                            <MenuItem className={classNames([appContext.language, page === "about" && "active"])} onClick={(e) => onOpenPage("about")}>
                                {t("about")}
                            </MenuItem>
                        </SlideRightToLeft>
                        <SlideRightToLeft delay={1100}>
                            <MenuItem className={classNames([appContext.language, page === "contact" && "active"])} onClick={(e) => onOpenPage("contact")}>
                                {t("contact")}
                            </MenuItem>
                        </SlideRightToLeft>
                        
                        <SocialMenuContainer>
                            <SlideDownToUp delay={1600}>
                                <SocialMenuItem>
                                    <Button onClick={(e) => openInNewTab('https://www.facebook.com/mypmc')}><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"><path d="M 25 3 C 12.861562 3 3 12.861562 3 25 C 3 36.019135 11.127533 45.138355 21.712891 46.728516 L 22.861328 46.902344 L 22.861328 29.566406 L 17.664062 29.566406 L 17.664062 26.046875 L 22.861328 26.046875 L 22.861328 21.373047 C 22.861328 18.494965 23.551973 16.599417 24.695312 15.410156 C 25.838652 14.220896 27.528004 13.621094 29.878906 13.621094 C 31.758714 13.621094 32.490022 13.734993 33.185547 13.820312 L 33.185547 16.701172 L 30.738281 16.701172 C 29.349697 16.701172 28.210449 17.475903 27.619141 18.507812 C 27.027832 19.539724 26.84375 20.771816 26.84375 22.027344 L 26.84375 26.044922 L 32.966797 26.044922 L 32.421875 29.564453 L 26.84375 29.564453 L 26.84375 46.929688 L 27.978516 46.775391 C 38.71434 45.319366 47 36.126845 47 25 C 47 12.861562 37.138438 3 25 3 z M 25 5 C 36.057562 5 45 13.942438 45 25 C 45 34.729791 38.035799 42.731796 28.84375 44.533203 L 28.84375 31.564453 L 34.136719 31.564453 L 35.298828 24.044922 L 28.84375 24.044922 L 28.84375 22.027344 C 28.84375 20.989871 29.033574 20.060293 29.353516 19.501953 C 29.673457 18.943614 29.981865 18.701172 30.738281 18.701172 L 35.185547 18.701172 L 35.185547 12.009766 L 34.318359 11.892578 C 33.718567 11.811418 32.349197 11.621094 29.878906 11.621094 C 27.175808 11.621094 24.855567 12.357448 23.253906 14.023438 C 21.652246 15.689426 20.861328 18.170128 20.861328 21.373047 L 20.861328 24.046875 L 15.664062 24.046875 L 15.664062 31.566406 L 20.861328 31.566406 L 20.861328 44.470703 C 11.816995 42.554813 5 34.624447 5 25 C 5 13.942438 13.942438 5 25 5 z"/></svg>    </Button>
                                </SocialMenuItem>
                            </SlideDownToUp>
                            <SlideDownToUp delay={1800}>
                                <SocialMenuItem>
                                    <Button onClick={(e) => openInNewTab('https://www.youtube.com/@pmcartist')}><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"><path d="M 24.402344 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.402344 16.898438 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.902344 40.5 17.898438 41 24.5 41 C 31.101563 41 37.097656 40.5 40.597656 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.097656 35.5 C 45.5 33 46 29.402344 46.097656 24.902344 C 46.097656 20.402344 45.597656 16.800781 45.097656 14.300781 C 44.699219 12.101563 42.800781 10.5 40.597656 10 C 37.097656 9.5 31 9 24.402344 9 Z M 24.402344 11 C 31.601563 11 37.398438 11.597656 40.199219 12.097656 C 41.699219 12.5 42.898438 13.5 43.097656 14.800781 C 43.699219 18 44.097656 21.402344 44.097656 24.902344 C 44 29.199219 43.5 32.699219 43.097656 35.199219 C 42.800781 37.097656 40.800781 37.699219 40.199219 37.902344 C 36.597656 38.601563 30.597656 39.097656 24.597656 39.097656 C 18.597656 39.097656 12.5 38.699219 9 37.902344 C 7.5 37.5 6.300781 36.5 6.101563 35.199219 C 5.300781 32.398438 5 28.699219 5 25 C 5 20.398438 5.402344 17 5.800781 14.902344 C 6.101563 13 8.199219 12.398438 8.699219 12.199219 C 12 11.5 18.101563 11 24.402344 11 Z M 19 17 L 19 33 L 33 25 Z M 21 20.402344 L 29 25 L 21 29.597656 Z"/></svg></Button>
                                </SocialMenuItem>
                            </SlideDownToUp>
                            <SlideDownToUp delay={2000}>
                                <SocialMenuItem>                                    
                                    <Button onClick={(e) => openInNewTab('https://www.instagram.com/pmcmusic')}><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"><path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"/></svg></Button>
                                </SocialMenuItem>
                            </SlideDownToUp>
                        </SocialMenuContainer>
                        <SocialMenuContainer>
                            <SlideUpToDown delay={2300}>
                                <StoreMenuItem>
                                    <Button onClick={(e) => comingSoon?setHiding(true):setComingSoon(true)}><img src={Statics.AppStoreIcon} alt={"PMC App Store"}/></Button>
                                </StoreMenuItem>
                            </SlideUpToDown>
                            <SlideUpToDown delay={2500}>
                                <StoreMenuItem>
                                    <Button onClick={(e) => comingSoon?setHiding(true):setComingSoon(true)}><img src={Statics.PlayStoreIcon} alt={"PMC Play Store"} /></Button>
                                </StoreMenuItem>
                            </SlideUpToDown>
                        </SocialMenuContainer>
                        <SocialMenuContainer noMargin={true}>
                            <SlideDownToUp delay={2800}>
                                <StoreMenuItem>
                                    <Button onClick={(e) => comingSoon?setHiding(true):setComingSoon(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="4em" ><path fill="#b0c1d4" d="M17.091,75.5C10.148,75.5,4.5,69.852,4.5,62.909V17.091C4.5,10.148,10.148,4.5,17.091,4.5h45.818 c6.942,0,12.591,5.648,12.591,12.591v45.818c0,6.942-5.648,12.591-12.591,12.591H17.091z" /><path fill="#66798f" d="M62.909,5C69.576,5,75,10.424,75,17.091v45.818C75,69.576,69.576,75,62.909,75H17.091 C10.424,75,5,69.576,5,62.909V17.091C5,10.424,10.424,5,17.091,5H62.909 M62.909,4H17.091C9.891,4,4,9.891,4,17.091v45.818 C4,70.109,9.891,76,17.091,76h45.818C70.109,76,76,70.109,76,62.909V17.091C76,9.891,70.109,4,62.909,4L62.909,4z"/><path fill="#2d3540" d="M25.559,30.184c0.794-0.953,2.065-1.588,3.177-1.747c0.159,1.271-0.318,2.542-0.953,3.336 c-0.794,0.953-1.906,1.588-3.177,1.588C24.289,32.249,24.765,31.137,25.559,30.184z M32.39,46.227 c-0.953,1.271-2.224,3.018-3.653,3.018c-1.43,0-1.747-0.953-3.495-0.794c-1.906,0-2.224,0.953-3.653,0.794 c-1.588,0-2.7-1.43-3.495-2.859c-2.383-3.653-2.7-8.101-1.112-10.484c1.112-1.588,2.7-2.542,4.289-2.542 c1.588,0,2.7,0.953,3.971,0.953c1.271,0,2.065-0.953,3.971-0.953c1.43,0,2.859,0.794,3.971,2.065 c-3.495,1.906-2.859,6.83,0.635,8.26C33.184,44.798,32.866,45.274,32.39,46.227z" /><g><path fill="#2d3540" d="M42.079 29.708v3.653h4.13v2.224h-4.13v8.578c0 1.906.477 3.018 2.224 3.018.794 0 1.271 0 1.588-.159l.159 2.224c-.477.159-1.43.318-2.542.318-1.271 0-2.383-.477-3.177-1.271-.794-.953-1.112-2.224-1.112-4.13v-8.578h-2.383v-2.224h2.383v-2.859L42.079 29.708zM52.087 33.361l3.177 8.895c.477 1.43.953 2.7 1.271 4.13h.159c.318-1.271.794-2.542 1.271-4.13l3.018-8.895H64l-6.195 15.885h-2.7l-6.036-15.885H52.087z" /></g></svg></Button>
                                </StoreMenuItem>
                            </SlideDownToUp>
                        </SocialMenuContainer>
                        <ComingSoonContainer>
                            {(comingSoon || hiding) && 
                                <SlideUpToDown isHiding={hiding} onRest={() => { setComingSoon(true); setHiding(false); }}>
                                    <SmallText className={classNames([appContext.language, "has-margin"])}>{t("comingSoon")}</SmallText>
                                </SlideUpToDown>
                            }
                        </ComingSoonContainer>
                        <SlideDownToUp delay={3000}>
                            <MobileHintText className={appContext.language} text={t("mobileHintText")} min={9} max={14} />
                        </SlideDownToUp>
                    </MenuWrapper>    
                </MenuItems>
            </MenuContainer>
            <OutSideMenu className={classNames([
                websiteContext.modal !== null && "has-page",
                websiteContext.menu && "open"
            ])} onClick={onCloseMenuButton}/>
        </>
        
    )
}
export const MenuIcon = ({style, hidden, delay}) => {
    const websiteContext = useContext(WebsiteContext)
    const appContext = useContext(AppContext)
    const onClick = (e) => {
        websiteContext.update({
            menu: true
        })
    }
    return (
        <MenuIconButton delay={delay} style={style} onClick={onClick} className={classNames([hidden && "hidden", appContext.isPwa && "pwa"])}>
            <div>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </MenuIconButton>
    )
}