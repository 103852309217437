import { useTranslation } from "react-i18next";
import {  Title } from "../../../GlobalComponents"
import { useContext, useEffect, useState } from "react";
import { FormButton, FormInput, FormGroup, FormLabel, FormInputGroup, FormTextArea } from "./Components";
import axios from "axios";
import { AppContext } from "../../../context/AppContext";
import { classNames } from "../../../helper";

export const PMCSms = () => {
    const {t} = useTranslation();
    const appContext = useContext(AppContext);
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [nameError, setNameError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [result, setResult] = useState(null);
    useEffect(() => {
        return () => {
            doResetForm();
        }
    }, [])
    const toPersianNumber = (text) => {
        if(appContext.language !== "fa-IR") return text;
        return text.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])
    }
    const onType = (e) => {
        if(e.target.name === "name"){
            setName(e.target.value);
            handleNameError(e.target.value);
        }
        if(e.target.name === "message"){
            setMessage(e.target.value);
            handleMessageError(e.target.value);
        }
    }
    const handleNameError = (value) => {
        if(value === null || value === "" || value.length === 0) {
            setNameError(true);
            return;
        }
        setNameError(false);
    }
    const handleMessageError = (value) => {
        if(value === null || value === "" || value.length === 0) {
            setMessageError(true);
            return;
        }
        setMessageError(false);
    }
    const onSubmit = async (e) => {
        if(submitting) return;
        handleValidation();
        if(!messageError && !nameError){
            setSubmitting(true);
            setTimeout(async () => {
                await doSend();
                doResetForm(false);
            }, 2000)
        }
        

    }
    const doResetForm = (result = true) => {
        setSubmitting(false);
        setName("");
        setMessage("");
        setNameError(false);
        setMessageError(false);
        if(result) setResult(null);
    }
    const doSend = async () => {
        try{
            const response = await axios.post("https://pmc.tv/backend/sms.php", {
                title: name,
                description: message
            });
            setResult({
                status: true,
                message: t('sendSuccessfully')
            })
            doResetForm(false);
            return true;
        }catch(e){
            console.error(e)
        }
        doResetForm(false);
        setResult({
            status: false,
            message: t('sendFailed')
        })
        return false;
    }
    const handleValidation = () => {
        handleNameError(name);
        handleMessageError(message);
    }
    return (
        <>
            <Title className={appContext.language} dangerouslySetInnerHTML={{ __html: t('pmcSmsTitle') }} />
            <div dangerouslySetInnerHTML={{ __html: t('pmcSmsText') }}></div>
            <form autocomplete="off">
                <FormGroup>
                    <FormLabel>{t('name')} ({toPersianNumber(name.length+"/14")})</FormLabel>
                    <FormInputGroup>
                        <FormInput type="text" className={classNames(nameError && "invalid")} name="name" maxLength={14} onChange={onType} onKeyUp={(e) => handleValidation()} />
                        {nameError && <small>{t("nameInputError")}</small>}
                    </FormInputGroup>
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t('message')} ({toPersianNumber(message.length+"/100")})</FormLabel>
                    <FormInputGroup>
                        <FormTextArea name="message" className={classNames(messageError && "invalid")} maxLength={100} onChange={onType} onKeyUp={(e) => handleValidation()}></FormTextArea>
                        {messageError && <small>{t("messageInputError")}</small>}
                    </FormInputGroup>
                </FormGroup>
                <FormGroup>
                    <FormButton type="button" name="btnSubmit" disabled={nameError || messageError || submitting} onClick={onSubmit}>
                        {submitting?t('submitting'):t('send')}
                    </FormButton>
                </FormGroup>

            </form>
        </>
    )
}