import React, { useEffect, useRef, useState } from 'react'
const DefaultOptions = {
    backgroundVideoLoop: true
}
const LoadComponents = {
    backgroundVideo: false,
    homeButtons: false,
    footer: false,
    menuIcon: false,
    header: false
}
const Values = {
    loader: true,
    transparency: false,
    menu: false,
    page: null,
    isHome: false,
    modal: null,
    radio: false,
    waitForAnimate: false,
    isPlaying: false,
    planetaVideoFile: null,
    selectVideoFile: null,
    desktopVideo: null,
    videoFiles: null,
    mobileVideo: null
}
const State = {
    loading: LoadComponents,
    options: DefaultOptions,
    isCanPlayOrPause: false
}

export const WebsiteContext = React.createContext(State)

export const WebsiteProvider = ({ children }) => {
    const [state, setState] = useState(State)
    const [values, setValues] = useState(Values)
    const [isMuted, setIsMuted] = useState(false)
    const [isVideoLoaded, setIsVideoLoaded] = useState(false)
    const [isPageLoaded, setIsPageLoaded] = useState(false)
    const [activeModal, setActiveModal] = useState(null);
    const [isShowBackVideo, setIsShowBackVideo] = useState(true);
    const refPlayer = useRef(null);
    const refModal = useRef(null);
    const update = (newState, delay = 0) => {
        setTimeout(() => {
            setValues({
              ...values,
              ...newState
            })
        }, delay)
    }
    const updateValues = (newState) => {
      setTimeout(() => {
        setValues({
          ...values,
          ...newState
        })
      }, 0)
    }
    const setIsCanPlayOrPause = (value) => setState({...state, isCanPlayOrPause: value});
    const openPage = (name, alone = false) => update({ page: name, menu: !alone })
    const closePage = () => update({ page: "home", menu: true })
    const openModal = (name, alone = false) => update({ modal: name, menu: !alone })
    const closeModal = () => update({ modal: null, menu: true })
    const initVideoPlayer = (ref) => {
      refPlayer.current = ref;
    }
    const setRefModal = (ref) => {
      refModal.current = ref;
    }
    const clearVideoPlayer = () => refPlayer.current = null;
    const animate = (keys, delay = 0) => {
      setTimeout(() => {  
        if(typeof keys === "string"){
          setState({
            ...state,
            loading: {
              ...state.loading,
              [keys]: true
            }
          })
        }else if(typeof keys === "object"){
          let newState = {
            ...state
          }
          keys.map((key) => newState.loading[key] = true);
          setState(newState);
        }
      }, delay)
    }
    useEffect(() => {
        if(values.menu || values.page !== null){
            update({ transparency: true })
        }if(values.menu || values.modal !== null){
          update({ transparency: true })
        }else
            update({ transparency: false })
    }, [values.menu, values.page, values.modal])
    
    return (
      <WebsiteContext.Provider value={{
            ...state,
            ...values,
            isVideoLoaded,
            isPageLoaded,
            activeModal,
            isMuted,
            isShowBackVideo,
            update,
            updateValues,
            animate,
            openPage,
            openModal,
            closePage,
            closeModal,
            initVideoPlayer,
            clearVideoPlayer,
            setIsCanPlayOrPause,
            setIsVideoLoaded,
            setIsPageLoaded,
            setActiveModal,
            setIsMuted,
            setIsShowBackVideo,
            refPlayer,
            refModal,
            setRefModal
        }}
      >
        {children}
      </WebsiteContext.Provider>
    );
  };