import BackgroundPlayer from "../../../components/BackgroundVideo/BackgroundPlayer";
import ChannelsTemplate from "../../../templates/desktop/WebsiteTemplate/ChannelsTemplate";
import { Statics } from "../../../components/General/StaticFiles";
import Page from "../../../components/General/Page";
const PlanetaChannel = ({style}) => {
    return (
        <Page path={"/planeta"} title="PMC PLANETA" description={"International songs and DJ remixes"}>
            <ChannelsTemplate style={style}>
                <BackgroundPlayer 
                    url={Statics.PlanetaMovie} 
                    radio={{
                        url: "https://s5.radio.co/s42d095d44/listen",
                        type: 'audio/mpeg'
                    }} 
                    loop={true}
                    poster={Statics.PlanetaMoviePreLoad}
                />
            </ChannelsTemplate>
        </Page>
        
    )
}
export default PlanetaChannel;