import { useContext, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { WebsiteContext } from "../../context/WebsiteContext";
import { AppContext } from "../../context/AppContext";
import Firebase from "./Firebase";
const ServiceWorker = ({children}) => {
    const appContext = useContext(AppContext);
    useEffect(() => {
        ReactGA.initialize('G-B8WBTT56XT');
        if('serviceWorker' in navigator){
            navigator.serviceWorker.register(`/service-worker.js?v=${process.env.REACT_APP_VERSION}`, {scope: "/"})
            .then((registration) => {
                console.log("Service worker registered with scope:", registration.scope, `version: ${process.env.REACT_APP_VERSION}`);
                const interval = setInterval(() => {
                    registration.active.postMessage({
                        action: 'CACHE_READY'
                    })
                }, 1000)
                navigator.serviceWorker.addEventListener('message', (event) => {
                    console.log(event)
                    if(!event.data) return;
                    if(event.data.action === "CACHE_READY"){
                        clearInterval(interval);
                        appContext.setIsCacheReady(true);
                    }
                })
                registration.onupdatefound = () => {
                    console.log("New service worker update founded.")
                    const newWorker = registration.installing;
                    newWorker.onstatechange = () => {
                        if(newWorker.state === 'installed' && navigator.serviceWorker.controller){
                            console.log("New service worker installed.")
                            newWorker.postMessage({
                                action: 'skipWaiting'
                            })
                        }
                    }
                }
                
            })
            .catch((err) => console.log("Service worker registration failed:", err));
            
        }
    }, [])
    
    return (
        <Firebase>
            {children}
        </Firebase>
    );
}
export default ServiceWorker;