import { useContext } from "react"
import { Title } from "../../../GlobalComponents"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../../context/AppContext"

export const Contact = () => {
    const appContext = useContext(AppContext);
    const {t} = useTranslation();
    return (
        <>
            <Title className={appContext.language} dangerouslySetInnerHTML={{ __html: t('contactPageTitle') }} />
            <div dangerouslySetInnerHTML={{ __html: t('contactPageText') }}></div>
            
        </>
    )
}