import { getToken, messaging, onMessage } from "../../firebase"
import {useEffect} from "react"
const Firebase = ({ children }) => {
    const VAPID_KEY = "BNgT4jadsVovntuF8bN3oTumQvYcX8tybk5h7KcvcVocz3VZxj8rVb6fkL4ohJEnVOhRF3dEygtbxCIzJqtmNk8";
    const requestNotificationPermission = async () => {
        if(localStorage.getItem('fcm') !== null) return;
        try{
            const permission = await Notification.requestPermission();
            if(permission === "granted"){
                if(localStorage.getItem("fcm")){
                    const fcmToken = JSON.parse(localStorage.getItem("fcm"));
                    return fcmToken.token;
                }
                const token = await getToken(messaging, {
                    vapidKey: VAPID_KEY
                });
                console.log("FCM Token:", token);
                const body = new URLSearchParams();
                body.append("action", "register:device");
                body.append("token", token);
                const result = await fetch(`${process.env.REACT_APP_API}api.php`, {
                    method: "POST",
                    body
                })
                const response = await result.json();
                const fcmToken = {
                    device_id: response['device_id'],
                    token
                }
                localStorage.setItem('fcm', JSON.stringify(fcmToken))
                console.log("Device ID: ", response['device_id'])
                return token;
            }else{
                console.log("Notification permission denied.");
                return null;
            }
        }catch(error){
            console.error("Error getting permission: ", error)
        }
    }
    useEffect(() => {
        requestNotificationPermission();
        /*onMessage(messaging, (payload) => {
            console.log("Message received. ", payload);
            new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: payload.notification.icon
            })
        })*/
    }, [])
    
    return children;
}
export default Firebase;