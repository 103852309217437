import styled from "styled-components";
import { WrapperTopCenter } from "../../../GlobalComponents";
import SlideDownToUp from "../../../components/Animation/SlideDownToUp";
import SlideUpToDown from "../../../components/Animation/SlideUpToDown";
import SlideLeftToRight from "../../../components/Animation/SlideLeftToRight";
import SlideRightToLeft from "../../../components/Animation/SlideRightToLeft";
import Animate from "../../../components/Animation/Animate";

export const HomeMenu = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    &.transparency{
        opacity: .6;
    }
    body.mobile & {
        width: 100%;
    }
`

export const WebsiteLogo = styled.div`
    width: 100%;
    text-align: center;
    position: relative;
    & img{
        max-width: 250px;
    }
    body.mobile &{
        text-align: left;
        & img{
            max-width: 180px;
        }
    }
    body.mobile.landscape &{
        text-align: center;
    }
    
`
export const WebsiteTitle = styled.h2`
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    text-align: center;
    text-transform: none;
    letter-spacing: -.04em;
    color: #e5e5e5;
    margin: 10px auto 25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    width: 100%;
    line-height: 100%;
    @supports ((text-stroke:1px #e5e5e5) or (-webkit-text-stroke:1px #e5e5e5)) {
        & {
            color: transparent;
            -webkit-text-stroke: 1px #e5e5e5;
            text-stroke: 1px #e5e5e5;
            text-shadow: none;
        }
    }
    body.mobile &{
        font-size: 20px;
        color: #e5e5e5;
        -webkit-text-stroke: 0;
        margin-bottom: 0;
        margin-top: 0.5em;
        margin-left: 0.5em;
        text-align: left;
    }
    body.mobile.landscape &{
        font-size: 30px;
        color: transparent;
        -webkit-text-stroke: 1px #e5e5e5;
        text-stroke: 1px #e5e5e5;
        text-shadow: none;
        margin-bottom: 0;
        margin-top: 0.5em;
        margin-left: 0em;
        text-align: center;
    }

`
export const WebsiteTitleContainer = styled.div`
    padding-top: 4em;
    &.transparency{
        opacity: .6;
    }
    body.mobile.landscape &{
        padding: 2em 1em 1em 1em;
    }
    body.mobile.portrait &{
        padding-top: 2em;
    }
    @supports (-webkit-touch-callout: none){
        body.mobile.portrait &.pwa{
            padding-top: 4em;
        }
    }
    
`
export const HomeMenuContainer = styled.div`
    padding-bottom: 4em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &.transparency{
        opacity: .6;
    }
`
export const HomeMenuWrapper = styled.div`

`
export const HomeContainer = styled.div`
    height: var(--app-height);
    & .header{
        height: 50%;
    }   
    & .menu{
        height: 50%;
    }
    body.mobile & {
        & .header{
            height: 20%;
        }   
        & .menu{
            height: 80%;
            align-items: center;
        }
    }
`
export const Footer = styled.div`
    padding-top: 2em;
    text-align: center;
    width: 100%;
    font-size: .8em;
    body.mobile & {
        position: absolute;
        bottom: 2em;
        font-size: .7em;
    }
`

export const Container = styled(WrapperTopCenter)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    & .container{
        position: relative;
    }
    &.transparency{
        opacity: .6;
    }

`
export const Header = styled.div`
    &.transparency{
        opacity: .6;
    }
    
`
export const MainContent = styled.div`
    position: relative;
`
export const PageContainerSlideDownToUp = styled(SlideDownToUp)`
    position: absolute;
    width: var(--app-width);
    height: var(--app-height);
    z-index: 3;
    top:0;
`
export const PageContainerSlideUpToDown = styled(SlideUpToDown)`
    position: absolute;
    width: var(--app-width);
    height: var(--app-height);
    z-index: 3;
    top:0;
`
export const PageContainerSlideLeftToRight = styled(SlideLeftToRight)`
    position: absolute;
    width: var(--app-width);
    height: var(--app-height);
    z-index: 3;
    top:0;
`
export const PageContainerSlideRightToLeft = styled(SlideRightToLeft)`
    position: absolute;
    width: var(--app-width);
    height: var(--app-height);
    z-index: 3;
    top:0;
`


export const PlayerLoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: var(--app-width);
    height: var(--app-height);
    overflow: hidden;
    background: #000;
    z-index: 999;

`
export const PageContainer = styled(Animate)`
    background: #000;
    width: 100%;
    height: 100%;
    &.hide{
        display: none;
    }
`