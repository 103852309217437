import styled from "styled-components";

export const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35%;

`
export const HomeMenuItem = styled.div`
    width: 100%;
    padding: 2em;
    & img{
        width: 100%;
    }
`
export const FormGroup = styled.div`
    margin: .5em 0;

`
export const FormInputGroup = styled.div`
    direction: rtl;
    & small{
        color: #c81c1c;
        font-size: .8em;
        margin: 0 .3em;
    }
`
export const FormInput = styled.input`
    width: 100%;
    direction: rtl;
    background: none;
    border: 2px solid var(--text-color);
    padding: 5px 10px;
    color: #fff;
    font-size: .9em;
    border-radius: 10px;
    outline: none;
    transition: all ease .5s;
    &:focus{
        transition: all ease .5s;
        border-color: var(--primary-color);
    }
    &.invalid{
        border-color: #c81c1c !important;
    }
    
`
export const FormTextArea = styled.textarea`
    width: 100%;
    direction: rtl;
    background: none;
    border: 2px solid var(--text-color);
    padding: 5px 10px;
    color: #fff;
    font-size: .9em;
    border-radius: 10px;
    height: 200px;
    outline: none;
    transition: all ease .5s;
    resize: none;
    &:focus{
        border-color: var(--primary-color);
        transition: all ease .5s;
    }
    &::-webkit-scrollbar-track {
        border: 1px solid #000;
        padding: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(255,255,255,0.2);
        border: 1px solid #000;
    }
    &.invalid{
        border-color: #c81c1c !important;
    }
`
export const FormLabel = styled.label`
    direction: rtl;
    margin-bottom: .2em;
    font-weight: bold;
    font-size: .9em;
`
export const FormButton = styled.button`
    direction: rtl;
    width: 100%;
    background: var(--primary-color);
    border: none;
    border-radius: 10px;
    padding: 5px;
    color: var(--text-color);
    font-weight: bold;
    font-size: .9em;
    &:disabled{
        opacity: .7;
    }

`