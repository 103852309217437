export const updateState = () => {
    
}
export const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

export const classNames = (classes) => {
    let out = [];
    classes.map(className => {
        if(className) out.push(className)
    });
    return out.join(" ")
}