import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Audio, AudioContainer } from "./Components"
import { PreLoader } from "../PreLoader/PreLoader";
import { VideoPlayer } from "./VideoPlayer";
import { WebsiteContext } from "../../context/WebsiteContext";
import { UnmuteContainer, UnmuteIcon } from "../BackgroundVideo/Components";
import { classNames } from "../../helper";
import { AppContext } from "../../context/AppContext";


export const AudioPlayer = forwardRef(({url, videoSrc, poster}, ref) => {
    const appContext = useContext(AppContext)
    const refPlayer = useRef(null);
    const refVideoPlayer = useRef(null);
    const abortController = useRef(null);
    const websiteContext = useContext(WebsiteContext);
    const [loading, setLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(null);
    
    useImperativeHandle(ref, () => {
        return {
            play: () => {
                setLoading(true);
                handlePlay();
            },
            pause: () => {
                if(loading) return;
                handlePause();
            },
            remove: () => handleStop,
            isMute: () => isMuted,
        }
    })
    const handleStop = () => {
        console.log("Stop handle")
        handlePause();
        websiteContext.clearVideoPlayer();

    }
    const handlePlay = () => {
        setIsMuted(false);
        if(refPlayer.current){
            refPlayer.current.src = url;
            refPlayer.current.volume = 1;
            refPlayer.current.muted = false;
            refPlayer.current.play().then(() => {
                abortController.current = new AbortController();
                setIsMuted(refPlayer.current.muted)
                websiteContext.updateValues({
                    isPlaying: true
                });
                refVideoPlayer.current.play();
                setLoading(false);
            }).catch((e) => {
                if(e.name === "NotAllowedError"){
                    setIsMuted(true)
                    setLoading(false);
                }
            });
        }
    }
    const handlePause = () => {
        const player = refPlayer.current;
        if(player){
            player.src = "";
            player.load();
            if(abortController.current)
                abortController.current.abort();
            setIsMuted(false)
            refVideoPlayer.current.pause();
            websiteContext.updateValues({
                isPlaying: false
            });
        }
    }
    useEffect(() => {
        handlePlay();
        return () => {
            handleStop();
        }
    }, []);
    useEffect(() => {
        if(ref.current){
            websiteContext.initVideoPlayer(ref.current);
            // if(ref.current.paused) handlePlay();
        }
    }, [ref]);
    const onLoadVideo = () => {
        console.log("Loaded video")
    }
    const onCanPlayThrough = (e) => {
        console.log("Audio Loaded")
        setLoading(false);
    }
    const onErrorLoading = (e) => {
        console.log("Error in loading: "+ e);
    }
    return (
        <AudioContainer ref={ref} >
            {(loading) && <PreLoader show={true} disableBackground={true} />}
            {videoSrc && <VideoPlayer ref={refVideoPlayer} poster={poster} src={videoSrc} loop={true} muted={true} isMainPlayer={false} onLoad={onLoadVideo}  />}
            <Audio autoPlay={false} muted={true} playsInline={true} webkit-playsinline preload="auto" onCanPlayThrough={onCanPlayThrough} onPause={handlePause} onError={onErrorLoading} ref={refPlayer}>
                <source src={url} type="audio/mpeg" />
            </Audio>
        </AudioContainer>
    )
})