import {WebsiteProvider } from "../context/WebsiteContext";
import { Outlet } from "react-router-dom";
import WebsiteTemplate from "../templates/desktop/WebsiteTemplate/WebsiteTemplate";

function WebsiteLayout(){
    return (
      <WebsiteProvider>
        <WebsiteTemplate>
          <Outlet />
        </WebsiteTemplate>
      </WebsiteProvider>
    )
}
export default WebsiteLayout;