import styled from "styled-components";
import SlideDownToUp from "../Animation/SlideDownToUp";

export const Container = styled(SlideDownToUp)`
    position: fixed;
    bottom: 1em;
    right: 0;
    width: 100%;
    max-width: 350px;
    z-index: 999;
    border-radius: 20px;
    display: flex;
    &.en-US{
        font-size: .8em;
        direction: ltr;
    }
    &.fa-IR{
        font-size: .8em;
        font-weight: bold;
        direction: rtl;
    }
    
    align-items: center;
    justify-content: center;
    &.hide{
        display: none;
    }
    body.mobile &{
        max-width: 100%;
        bottom: 4em;
    }
}
`
export const Wrapper = styled.div`
    background: #fff;
    width: 90%;
    padding: .5em;
    text-align: center;
    border-radius: 20px;
    color: #000;
`
export const InstallText = styled.div`
    
`
export const InstallButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const InstallButton = styled.button`
    background: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 10px;
    margin-top: .3em;
    padding: .3em 1em;
`