
import SlideDownToUp from "./SlideDownToUp";
import SlideUpToDown from "./SlideUpToDown";
import SlideLeftToRight from "./SlideLeftToRight";
import SlideRightToLeft from "./SlideRightToLeft";


const Animate = (props) => {
    const {animation, children} = props;
    if(animation === "down-to-up")
        return <SlideDownToUp {...props}>{children}</SlideDownToUp>
    if(animation === "up-to-down")
        return <SlideUpToDown {...props}>{children}</SlideUpToDown>
    if(animation === "left-to-right")
        return <SlideLeftToRight {...props}>{children}</SlideLeftToRight>   
    return <SlideRightToLeft {...props}>{children}</SlideRightToLeft>   
}
export default Animate;