import styled from "styled-components";
import { Unmute } from "../Player/Unmute";


export const FullScreenVideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.hide{
        opacity: 0;
    }
    &.transparency{
        opacity: .4;
    }
    & video{
        height: var(--app-height);
        width: var(--app-width);
        object-fit: cover;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    &.rotate{
        & video{
            width: var(--app-height);
            height: var(--app-width);
            transform: translate(-50%, -50%) scaleX(1) scaleY(1) rotate(-90deg);
            transition: transform .3s ease;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-right: -50%;
        }
    }
    &:after{
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`

export const PlayerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`
export const PlayerWrapper = styled.div`
    width: 100%;
    height: 100%;
    & .video-js{
        height: var(--app-height);
        width: var(--app-width);
    }
    & .video-js .vjs-tech{
        height: var(--app-height);
        width: var(--app-width);
        pointer-events: none;
    }
    & .video-js .vjs-control-bar{
        top: 0;
        left: 0;
        right:0;
        bottom: auto;
        width: var(--app-width);
    }
`
export const UnmuteContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    body.mobile &, body.tablet & {
        height: 100%;
        &.rotate{
            width: var(--app-height);
            height: var(--app-width);
            transform: translate(-50%, -50%) scaleX(1) scaleY(1) rotate(-90deg);
            position: absolute;
            left: 50%;
            top: 50%;
            margin-right: -50%;
        }
    }
`
export const UnmuteIcon = styled(Unmute)`
    
`