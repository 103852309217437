import styled from "styled-components";


export const ResponsiceTextContainer = styled.div`
    width: 100%;
    text-align: center;
    overflow: hidden;

`
export const ResponsiceTextWrapper = styled.span`
    white-space: nowrap;
    display: inline-block;

`
