import { useContext } from "react";
import BackgroundVideo from "../../../components/BackgroundVideo/BackgroundVideo.js";
import {MainContainer} from "./Components.js"
import {WebsiteContext} from "../../../context/WebsiteContext";
import {WebsiteMenu} from "../../../components/WebsiteMenu/WebsiteMenu.js";
import { PreLoader } from "../../../components/PreLoader/PreLoader.js";
function WebsiteTemplate({children}){
    const websiteContext = useContext(WebsiteContext)
    return (
        <MainContainer>
            <PreLoader show={websiteContext.loader}/>
            <BackgroundVideo />
            <WebsiteMenu />
            {children}
        </MainContainer>
    )
}
export default WebsiteTemplate;