import BackgroundPlayer from "../../../components/BackgroundVideo/BackgroundPlayer";
import ChannelsTemplate from "../../../templates/desktop/WebsiteTemplate/ChannelsTemplate";
import Page from "../../../components/General/Page";
const MusicChannel = ({className, style}) => {
    
    return (
        <Page path={"/music"} title="PMC MUSIC" description={"Persian and international music videos"}>
            <ChannelsTemplate style={style} className={className}>
                <BackgroundPlayer url={"https://hls.pmchd.live/hls/stream.m3u8"} isHls={true} />
            </ChannelsTemplate>
        </Page>
        
    )
};
export default MusicChannel;