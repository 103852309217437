import { useTranslation } from "react-i18next";
import { Title } from "../../../GlobalComponents"
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

export const About = () => {
    const {t} = useTranslation();
    const appContext = useContext(AppContext);
    return (
        <>
            <Title className={appContext.language} dangerouslySetInnerHTML={{ __html: t('aboutPageTitle') }} />
            <div dangerouslySetInnerHTML={{ __html: t('aboutPageText') }}></div>
        </>
    )
}