import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyCLNNp2w_tJhU3YMIt7hJTor5-UX9dGfDg",
    authDomain: "pmcapp-bf4c5.firebaseapp.com",
    projectId: "pmcapp-bf4c5",
    storageBucket: "pmcapp-bf4c5.firebasestorage.app",
    messagingSenderId: "557789397957",
    appId: "1:557789397957:web:fe9145738ce9ef9f4b81ac",
    measurementId: "G-YYLLQC9FF2"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage }