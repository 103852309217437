import styled from 'styled-components';
import { WrapperCenter } from '../../../GlobalComponents';
export const MainContainer = styled(WrapperCenter)`
    width: var(--app-width);
    height: var(--app-height);
    position: relative;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: var(--background-color);
`

export const BottomMenuContainer = styled.div`
    width: 100%;
    height: 150px;
    position: fixed;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    body.mobile &, body.tablet & {
        height: 100%;
        &.rotate{
            width: var(--app-height);
            height: var(--app-width);
            transform: translate(-50%, -50%) scaleX(1) scaleY(1) rotate(-90deg);
            position: absolute;
            left: 50%;
            top: 50%;
            margin-right: -50%;
        }
    }

`
export const BottomMenuWrapper = styled.div`
    width: fit-content;
    padding: 1em;
    border-radius: 20px;
    margin-bottom: 1em;
    background: rgba(0,0,0,.4);
    body.mobile &,body.tablet & {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 1em;
    }
`
