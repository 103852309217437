
export const Statics = {
    Logo: "/assets/img/pmc.png",
    HomeIcon: "/assets/img/home.png",
    MusicChannelButton: "/assets/img/btnmusic.png",
    RoyaleChannelButton: "/assets/img/btnroyale.png",
    SelectChannelButton: "/assets/img/btnselect.png",
    PlanetaChannelButton: "/assets/img/btnplaneta.png",
    ExitFullscreenIcon: "/assets/img/exit-fullscreen.png",
    OpenFullscreenIcon: "/assets/img/open-fullscreen.png",
    DesktopIntro: "/assets/intro-desktop.mp4",
    AppStoreIcon: "/assets/img/appstore.png",
    PlayStoreIcon: "/assets/img/googleplay.png",
    PlanetaMovie: "/assets/planeta.mp4",
    PlanetaMoviePreLoad: "/assets/img/planeta-frame-001.jpg",
    SelectMovie: "/assets/select.mp4",
    SelectMoviePreLoad: "/assets/img/select-frame-001.jpg"
};