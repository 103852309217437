import { AppProvider } from './context/AppContext'
import { Suspense } from "react";
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoute';
import ResponsiveContainer from "./components/Responsive/ResponsiveContainer"
import { PreLoader } from './components/PreLoader/PreLoader';
import { HelmetProvider } from 'react-helmet-async';
const App = () => {
  return (
      <Suspense fallback={<PreLoader show={true}/>}>
          <AppProvider>
            <HelmetProvider>
              <ResponsiveContainer>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </ResponsiveContainer>
            </HelmetProvider>
          </AppProvider>
      </Suspense>
    
  );
}
export default App;
