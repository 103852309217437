import styled from "styled-components";


export const MenuItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    &.home{
        &.landscape{
            & .menu-item{
                padding: 0 .5em;
            }
        }
    }
    
    &.home{
        & .menu-item{
            width: 25%;
            padding: 0 1.5em;
            & img{
                width: 100%;
                body.mobile &{
                    padding: 5px;
                    border-radius: 15px;
                    opacity: 1; 
                }
                
            }
        }
    }
    &.toolbar{
        & .menu-item{
            padding: 0;
            & img{
                height: 80px;
                width: auto;
                margin: 0 .5em;
                padding: 5px;
                body.mobile & {
                    opacity: 1;
                    height: 60px;
                    border-radius: 15px;
                    margin: 0 .3em;
                    padding: 3px;
                }
            }
        }
    }
    body.mobile &, body.tablet &{
        &.landscape{
            &.home{
                margin-top: 2em;
            }
        }
        &.home{
            flex-wrap: wrap;
            & .menu-item{
                flex: 1 1 50%;
                margin: 1em 0;
                & div{
                    width: 100%;
                }
            }
            
        }
        &.toolbar{
            width: 100%;
            height: 100%;    
        }
        &.landscape{
            flex-wrap: nowrap;
        }
    }
`
export const MenuItemButton = styled.div`
    
`
export const ControlsBarContainer = styled.div`
    padding: 0 1em;
`
export const MenuItem = styled.div`
    width: fit-content;
    padding: 0 .5em;
    
    cursor: pointer;
    div{
        
    }
    &:hover img, &.active img{
        transition: all ease-in-out .4s;
        box-shadow: 0px 0px 10px var(--primary-color);
        opacity: 1;
        background-color: var(--dark-background-color);
    }
    & img, .menu-item{
        transition: all ease-in-out .4s;
        border-radius: 25px;
        border: 2px solid var(--primary-color);
        padding: 15px;
        opacity: .5;
    }
`