import { useEffect, useRef } from "react";
import { ResponsiceTextContainer, ResponsiceTextWrapper } from "./Components";

const ResponsiveText = ({style, className, text, min, max}) => {
    const textRef = useRef(null);
    useEffect(() => {
        const handleAdjustFontSize = () => {
            const parent = textRef.current.parentElement;
            let fontSize = max;
            textRef.current.style.fontSize = `${fontSize}px`;
            while(textRef.current.scrollWidth > parent.clientWidth && fontSize > min){
                fontSize -= 1;
                textRef.current.style.fontSize = `${fontSize}px`;
            }
        }
        handleAdjustFontSize();
        window.addEventListener('resize', handleAdjustFontSize);
        return () => {
            window.removeEventListener('resize', handleAdjustFontSize)
        }
    }, [text, min, max])
    return (
        <ResponsiceTextContainer style={style} className={className}>
            <ResponsiceTextWrapper ref={textRef}>{text}</ResponsiceTextWrapper>
        </ResponsiceTextContainer>
    )
}
export default ResponsiveText;