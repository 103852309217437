import { useContext, useEffect, useState } from "react";
import { CloseContainer, HeaderContainer, OutSide, PopupContainer, Wrapper } from "./Components"
import { CloseIcon } from "../WebsiteMenu/Components";
import { About } from "../../scenes/Website/Pages/About";
import { Advertising } from "../../scenes/Website/Pages/Advertising";
import { SubmitVideo } from "../../scenes/Website/Pages/SubmitVideo";
import { Contact } from "../../scenes/Website/Pages/Contact";
import { SatelliteFrequencies } from "../../scenes/Website/Pages/SatelliteFrequencies";
import { PMCSms } from "../../scenes/Website/Pages/PMCSms";
import { AppContext } from "../../context/AppContext";
import { useSwipeable } from "react-swipeable/es";

const PagePopup = ({ name, onClose, onCloseMenu }) => {
    const [closing, setClosing] = useState(false);
    const [active, setActive] = useState(null);
    const appContext = useContext(AppContext)
    const handlers = useSwipeable({
        onSwipedLeft: (e) => {
            if(onClose) onClose();
        },
        delta: 10,
        trackTouch: true,
        trackMouse: false
    });
    useEffect(() => {
        setTimeout(() => {
            if(active !== null){
                setClosing(true);
            }else{
                setActive(name)        
            }
        }, 0)
    }, [name])
    const handleClose = () => {
        setClosing(false);
        setActive(name);

        if(!appContext.isMobile && name === null && onCloseMenu) onCloseMenu();
    }
    if(!active) return null;
    return (
        <>
            <PopupContainer className={appContext.language} isHiding={closing} onHided={handleClose}>
                <HeaderContainer>
                    <CloseContainer>
                        <CloseIcon className="close-btn" onClick={onClose}><i></i><i></i></CloseIcon>
                    </CloseContainer>
                </HeaderContainer>
                
                <Wrapper {...handlers}>
                    {active === "about" && <About />}
                    {active === "advertising" && <Advertising />}
                    {active === "submitVideo" && <SubmitVideo />}
                    {active === "contact" && <Contact />}
                    {active === "satelliteFrequencies" && <SatelliteFrequencies />}
                    {active === "PMCSms" && <PMCSms />}
                    
                </Wrapper>
            </PopupContainer>
            {active && <OutSide className={"open"} onClick={onClose}/>}
        </>
        
    )
}
export default PagePopup